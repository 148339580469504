
import store from "../../index"
import { Module,VuexModule,Mutation,getModule } from "vuex-module-decorators"
import { AuthentItemType } from "../../../Type/index"


export interface AuthentType<T=string>{
    State:T;
    AuthList:Array<AuthentItemType>;
    name:T;
    idCard:T|number;
    otherCard:T|number;
    message:T;
    LeftImg:T;
    RightImg:T;
    OtherImg:T;

    SetName(data:T):void;
    SetIdCard(data:T|number):void;
    SetOtherCard(data:T|number):void;
    SetMessage(data:T):void;
    SetLeftImg(data:T):void;
    SetRightImg(data:T):void;
    SetOtherImg(data:T):void;
    SetAuthList( data?:Array<AuthentItemType> ):void;

    SetState(data:string):void;
    Clear():void;
}

@Module({
    name:"Authent",
    store,
    dynamic:true
})
export default class Authent extends VuexModule implements AuthentType{
    State = "";
    name = "";
    idCard:string|number = "";
    otherCard:string|number = "";
    message = "";
    LeftImg = "";
    RightImg = "";
    OtherImg = "";
    AuthList :Array<AuthentItemType> = [ ]

    get GetName():string{
        return this.name
    }
    @Mutation
    SetName( data:string ):void{
        this.name = data
    }

    get GetIdCard():string|number{
        return this.idCard
    }
    @Mutation
    SetIdCard(data:string|number){
        this.idCard = data
    }

    get GetOtherCard():string|number{
        return this.otherCard
    }
    @Mutation
    SetOtherCard(data:string|number){
        this.otherCard = data
    }

    get GetMessage():string{
        return this.message
    }
    @Mutation
    SetMessage(data:string){
        this.message = data
    }

    get GetLeftImg():string{
        return this.LeftImg
    }
    @Mutation
    SetLeftImg(data:string){
        this.LeftImg = data
    }

    get GetRightImg():string{
        return this.RightImg
    }
    @Mutation
    SetRightImg(data:string){
        this.RightImg = data
    }

    get GetOtherImg():string{
        return this.OtherImg
    }
    @Mutation
    SetOtherImg(data:string){
        this.OtherImg = data
    }

    get GetAuthList():Array<AuthentItemType>{
        return this.AuthList
    }

    @Mutation
    SetAuthList( List:Array<any> ){
        this.AuthList = List
    }

    get GetState():string{
        return this.State
    }
    @Mutation
    SetState(data:string){
        this.State = data
    }

    @Mutation
    Clear(){
        this.name = "";
        this.idCard = "";
        this.otherCard = "";
        this.message = "";
        this.LeftImg = "";
        this.RightImg = "";
        this.OtherImg = "";
    }
}

export const AuthentStore = getModule( Authent )
